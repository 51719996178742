<template>
  <div class="home">
    <div id="home-top">
      <img alt="" src="@/assets/img/home-1.jpg">
      <div id="top-box">
        <div class="container d-flex align-items-center h-100">
          <div class="row d-flex justify-content-center align-items-center w-100">
            <div class="col-md-12 text-center">
              <img class="mb-4" src="@/assets/logo.png" style="width: 215px; max-width: 100%">
              <h1 class="mb-4">ZERTI CARBON</h1>
              <h2 class="mb-4">BLOCKCHAIN CERTIFIED CARBON SINK SOLUTIONS</h2>
              <button class="btn btn-primary btn-lg mb-5" @click="$router.push('/areas')">Find your
                trees
              </button>
              <transition name="fade">
                <div class="table-responsive pb-4 d-none d-sm-block">
                  <table id="home-table">
                    <tr>
                      <td>Forests</td>
                      <td>Total Farmed Ha</td>
                      <td>Total Trees</td>
                      <td>CCO2 Tons Farmed per Year</td>

                    </tr>

                    <tr>
                      <td>
                        <template v-if="stats">
                          {{ stats.areasQty }}
                        </template>
                        <LazyLoadingPlaceholder v-else />
                      </td>
                      <td>
                        <template v-if="stats">
                          {{ new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(stats.totalArea) }} Ha
                        </template>
                        <LazyLoadingPlaceholder v-else />
                      </td>
                      <td>
                        <template v-if="stats">
                          {{ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(stats.totalTrees) }}
                        </template>
                        <LazyLoadingPlaceholder v-else />
                      </td>
                      <td>
                        <template v-if="stats">
                          {{ new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(stats.totalTheoreticalYearlyIncrement) }}
                        </template>
                        <LazyLoadingPlaceholder v-else />
                      </td>
                    </tr>

                  </table>
                </div>
              </transition>
              <transition name="fade">
                <div v-if="stats" class="row align-items-end pt-4 d-block d-sm-none">
                  <div class="col-sm pb-5">
                    <h4 class="mb-3">Forests</h4>
                    <h3 class="font-weight-bold">{{ stats.areasQty }}</h3>
                  </div>
                  <div class="col-sm pb-5">
                    <h4 class="mb-3">Total Farmed Ha</h4>
                    <h3 class="font-weight-bold">{{ parseFloat(stats.totalArea).toFixed(2) }}
                      Ha</h3>
                  </div>
                  <div class="col-sm pb-5">
                    <h4 class="mb-3">Total Trees</h4>
                    <h3 class="font-weight-bold">{{ stats.totalTrees }}</h3>
                  </div>
                  <div class="col-sm pb-5">
                    <h4 class="mb-3">ZCO2 Farmed per Year</h4>
                    <h3 class="font-weight-bold">{{ stats.totalTheoreticalYearlyIncrement }}</h3>
                  </div>
                  <div class="col-sm pb-5">
                    <h4 class="mb-3">Total Yearly Farming Profit</h4>
                    <h3 class="font-weight-bold">
                      {{ parseFloat(stats.totalTheoreticalYearlyIncrementProfit).toFixed(2) }}
                      €</h3>
                  </div>
                </div>
              </transition>
              <h5 class="mb-4">Forestation · Digitalization · Monetization</h5>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>


import Stats from "../api/Stats";
import LazyLoadingPlaceholder from "@/components/Home/LazyLoadingPlaceholder.vue";

export default {
  name: 'Home',
  components: {
    LazyLoadingPlaceholder,
  },
  data() {
    return {
      stats: null
    }
  },
  created() {
    Stats.getStats()
      .then((stats) => {
        this.stats = stats.data
      }).catch((error) => {
      console.log(error)
    })
  }
}
</script>

<style scoped>

</style>
